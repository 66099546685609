import React from "react";
import {UnitProps} from "./Unit";

export function Base(props: UnitProps) {
    return (
        <svg y={-props.size / 2} x={-props.size / 2} width={props.size}
             height={props.size}
             viewBox="0 0 510 450" className={"unit"}>
            <path
                className={props.empire}
                d="M357.738,118.549V98.766V82.925c0-4.783-3.878-8.661-8.661-8.661h-13.524c-4.783,0-8.661,3.878-8.661,8.661v10.77
		c0,2.801-2.271,5.071-5.071,5.071h-8.202c-2.801,0-5.071-2.271-5.071-5.071v-10.77c0-4.783-3.878-8.661-8.661-8.661h-13.559
		c-4.783,0-8.661,3.878-8.661,8.661v10.77c0,2.801-2.271,5.071-5.071,5.071h-8.27c-2.773,0-5.032-2.229-5.07-5.002l-0.15-10.958
		c-0.065-4.736-3.922-8.542-8.659-8.542h-13.644c-4.783,0-8.661,3.878-8.661,8.661v10.77c0,2.801-2.27,5.071-5.071,5.071h-8.202
		c-2.801,0-5.071-2.271-5.071-5.071v-10.77c0-4.783-3.878-8.661-8.661-8.661h-13.557c-4.783,0-8.661,3.878-8.661,8.661v10.77
		c0,2.801-2.271,5.071-5.071,5.071h-8.203c-2.801,0-5.071-2.271-5.071-5.071v-10.77c0-4.783-3.878-8.661-8.661-8.661h-13.541
		c-4.783,0-8.661,3.878-8.661,8.661v15.841v19.783l18.188,24.295h0.109v270.338H339.44V142.844h0.111L357.738,118.549z
		M267.596,243.81h-47.779v-40.138c0-13.186,10.691-23.883,23.891-23.883c13.201,0,23.889,10.697,23.889,23.889V243.81z
		M487.033,198.133c-0.004-2.309-0.931-4.521-2.574-6.145c-1.643-1.623-3.866-2.522-6.176-2.499l-13.541,0.14
		c-4.748,0.049-8.571,3.911-8.571,8.66v10.541c0,2.8-2.271,5.07-5.071,5.07h-8.221c-2.8,0-5.07-2.271-5.07-5.07v-10.77
		c0-4.782-3.877-8.66-8.66-8.66h-13.541c-4.783,0-8.66,3.878-8.66,8.66v10.77c0,2.8-2.271,5.07-5.07,5.07h-8.19
		c-2.8,0-5.07-2.271-5.07-5.07v-10.77c0-4.782-3.877-8.66-8.66-8.66H366.4c-4.783,0-8.66,3.878-8.66,8.66v215.116h129.705
		L487.033,198.133z
		M129.296,198.043c-0.009-4.777-3.884-8.645-8.661-8.645h-13.341c-4.737,0-8.596,3.807-8.66,8.543l-0.149,10.956
		c-0.037,2.773-2.296,5.002-5.07,5.002h-8.257c-2.801,0-5.071-2.27-5.071-5.071V198.06c0-4.783-3.877-8.661-8.66-8.661H57.885
		c-4.783,0-8.661,3.878-8.661,8.661v10.769c0,2.801-2.271,5.071-5.071,5.071h-8.219c-2.801,0-5.071-2.27-5.071-5.071V198.06
		c0-4.783-3.878-8.661-8.661-8.661H8.66c-4.783,0-8.66,3.878-8.66,8.661v215.121h129.709L129.296,198.043z"/>
        </svg>

    );
}

