import React from "react";
import {UnitProps} from "./Unit";

export function Spearman(props: UnitProps) {
    return (
        <svg y={-props.size / 2} x={-props.size / 2} width={props.size}
             height={props.size}
             viewBox="0 0 12650 12000" className={"unit"} transform={"scale(1 -1)"}>
            <path
                className={props.empire}
                d="M12550 11153 c-36 -12 -231 -79 -435 -149 -1484 -507 -3095 -1124
-4270 -1637 -1261 -550 -2211 -1053 -2742 -1454 -134 -101 -178 -148 -194
-205 -50 -185 142 -530 530 -953 56 -60 98 -112 93 -116 -4 -3 -52 -18 -107
-33 -152 -41 -293 -113 -370 -191 -97 -97 -167 -262 -191 -452 -5 -43 -10 -53
-26 -53 -54 0 -183 -36 -257 -71 -150 -71 -275 -225 -327 -405 -15 -52 -15
-53 16 -121 l32 -68 -76 -70 c-42 -38 -1010 -925 -2150 -1970 -1141 -1045
-2075 -1905 -2075 -1911 -1 -6 88 -61 197 -123 l198 -112 -43 -226 c-23 -125
-41 -228 -39 -230 2 -2 100 14 217 37 118 23 233 45 257 49 35 7 42 6 41 -8 0
-9 -15 -92 -33 -186 -18 -93 -29 -171 -25 -173 5 -2 106 6 225 18 118 12 218
20 220 18 2 -2 -14 -83 -35 -179 -27 -127 -34 -175 -26 -177 7 -2 1006 880
2220 1959 l2207 1962 89 -43 89 -44 75 11 c194 28 311 144 366 363 12 47 23
86 24 88 2 2 25 -8 52 -21 71 -37 127 -49 220 -48 97 0 171 19 263 67 188 98
326 279 367 481 l17 81 96 -83 c337 -290 703 -519 945 -591 71 -21 112 -27
195 -28 161 -1 209 26 339 189 657 831 1668 2525 2878 4825 358 680 1030 1999
1021 2004 -2 1 -32 -8 -68 -21z"/>
        </svg>
    );
}

